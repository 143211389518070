<template>
  <div class="autoInfo">
    <div class="auto_item">
      <span>商城名称</span>
      <span>{{ info.shop_name }}</span>
    </div>
    <div class="auto_item">
      <span>所属服务商</span>
      <span>{{ info.dist_name }}</span>
    </div>
    <div class="auto_item" v-if="info.shop_business_license">
      <span>营业执照</span>
      <img :src="info.shop_business_license" alt="" class="yy_img" />
    </div>
  </div>
</template>
<script>
import { getShopInfo } from "@/services";
export default {
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.getShopInfo();
  },
  methods: {
    //   获取信息接口
    async getShopInfo() {
      const res = await getShopInfo();
      if (res.code === 0) {
        this.info = res.data;
      }
      console.log(res);
    }
  }
};
</script>
<style lang="less" scoped>
.autoInfo {
  padding: 0.3rem 0.16rem;
  .auto_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    .yy_img {
      width: 3rem;
    }
  }
}
</style>
