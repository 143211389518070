var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "autoInfo" }, [
    _c("div", { staticClass: "auto_item" }, [
      _c("span", [_vm._v("商城名称")]),
      _c("span", [_vm._v(_vm._s(_vm.info.shop_name))]),
    ]),
    _c("div", { staticClass: "auto_item" }, [
      _c("span", [_vm._v("所属服务商")]),
      _c("span", [_vm._v(_vm._s(_vm.info.dist_name))]),
    ]),
    _vm.info.shop_business_license
      ? _c("div", { staticClass: "auto_item" }, [
          _c("span", [_vm._v("营业执照")]),
          _c("img", {
            staticClass: "yy_img",
            attrs: { src: _vm.info.shop_business_license, alt: "" },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }